import StepIcon from '@mui/material/StepIcon';
import { styled } from '@mui/material/styles';

export const CustomStepIcon = styled(StepIcon)(({ theme, editmode }) => ({
    width: 36,
    height: 36,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: editmode ? 'pointer' : 'default',
    ...(editmode && {
        '&:hover': {
            color: theme.palette.primary.light,
        },
    }),
    '&.active': {
        color: theme.palette.primary.main,
    },
    '&.completed': {
        color: theme.palette.primary.main,
    },
    text: {
        fontSize: 12,
    },
}));
