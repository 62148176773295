import { useTheme } from '@emotion/react';
import { Add as AddIcon, Delete as DeleteIcon, Remove as RemoveIcon } from '@mui/icons-material';
import {
    Box,
    Button,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip as TooltipMui,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';

import LocalSegmentQueryBuilder from '../../../../../QBuilder/components/LocalSegmentQBuilder';
import { DELAY_BEFORE_ERROR } from '../../constant';

export const TableCreationForm = ({
    tables,
    setTables,
    areQueryValidated,
    allTableQuerySet,
    handleNext,
    handleBack,
    setValidationCheck,
}) => {
    const [showErrors, setShowErrors] = useState(false);
    const theme = useTheme();

    const styles = {
        table: {
            minWidth: 650,
            marginTop: theme.spacing(3),
        },
        tableCell: {
            textAlign: 'center',
            verticalAlign: 'top',
            padding: theme.spacing(1),
        },
        buttonContainer: {
            margin: theme.spacing(2),
            display: 'flex',
            justifyContent: 'flex-end',
        },
        center: {
            width: '100%',
            textAlign: 'center',
        },
        section: {
            backgroundColor: theme.palette.common.sectionGray,
            margin: `4px 0 20px 0`,
            borderRadius: '5px',
            padding: theme.spacing(3),
        },
        sectionTitle: {
            marginBottom: theme.spacing(2),
        },
        name: {
            marginBottom: theme.spacing(3),
        },
        textfiled: {
            marginTop: '8px',
        },
    };
    const handleTableNameChange = (index, e) => {
        const updatedTables = [...tables];
        updatedTables[index].title = e.target.value;
        setTables(updatedTables);
    };

    const handleDeleteTable = (index) => {
        setTables(tables.filter((_, i) => i !== index));
    };

    const handleSubjectChange = (tableIndex, subjectIndex, value, subIndex = undefined) => {
        const updatedTables = [...tables];
        if (subIndex !== undefined)
            updatedTables[tableIndex].data[subjectIndex].subcategories[subIndex].subject = value;
        else updatedTables[tableIndex].data[subjectIndex].subject = value;
        setTables(updatedTables);
    };

    const handleQueryChange = (tableIndex, subjectIndex, queryIndex, nodes) => {
        const updatedTables = [...tables];

        if (typeof nodes === 'function') {
            const currentQuery =
                queryIndex !== undefined
                    ? updatedTables[tableIndex].data[subjectIndex].subcategories[queryIndex].query
                    : updatedTables[tableIndex].data[subjectIndex].query;

            const updatedNodes = nodes(currentQuery);

            if (queryIndex !== undefined) {
                updatedTables[tableIndex].data[subjectIndex].subcategories[queryIndex].query = updatedNodes;
            } else {
                updatedTables[tableIndex].data[subjectIndex].query = updatedNodes;
            }
        } else {
            if (queryIndex !== undefined) {
                updatedTables[tableIndex].data[subjectIndex].subcategories[queryIndex].query = nodes;
            } else {
                updatedTables[tableIndex].data[subjectIndex].query = nodes;
            }
        }

        setTables(updatedTables);
    };

    const handleAddSubcategory = (tableIndex, subjectIndex) => {
        const updatedTables = [...tables];
        if (!updatedTables[tableIndex].data[subjectIndex].subcategories) {
            updatedTables[tableIndex].data[subjectIndex].subcategories = [];
        }
        if (updatedTables[tableIndex].data[subjectIndex].query) {
            delete updatedTables[tableIndex].data[subjectIndex].query;
        }
        updatedTables[tableIndex].data[subjectIndex].subcategories.push({ subject: '', query: [] });
        setTables(updatedTables);
    };

    const handleRemoveSubcategory = (tableIndex, subjectIndex) => {
        const updatedTables = [...tables];
        const subcategories = updatedTables[tableIndex].data[subjectIndex].subcategories;
        if (subcategories && subcategories.length > 0) {
            subcategories.pop();
            if (subcategories.length === 0) {
                delete updatedTables[tableIndex].data[subjectIndex].subcategories;
                updatedTables[tableIndex].data[subjectIndex].query = [];
            }
            setTables(updatedTables);
        }
    };

    const getAndSetSubcategoryId = (tableIndex, subjectIndex, subIndex) => {
        let currentId = undefined;

        if (subIndex !== undefined) currentId = tables[tableIndex].data[subjectIndex].subcategories[subIndex]?.id;
        else currentId = tables[tableIndex].data[subjectIndex]?.id;

        if (currentId) return currentId;

        const updatedTables = [...tables];
        currentId = uuidv1();
        if (subIndex !== undefined) updatedTables[tableIndex].data[subjectIndex].subcategories[subIndex].id = currentId;
        else updatedTables[tableIndex].data[subjectIndex].id = currentId;

        setTables(updatedTables);
        return currentId;
    };

    const handleAddTable = () => {
        setTables([...tables, { title: 'New Table', data: [{ subject: '', query: [] }] }]);
    };

    const handleAddRow = (tableIndex) => {
        const updatedTables = [...tables];
        updatedTables[tableIndex].data.push({
            subject: '',
            query: [],
        });
        setTables(updatedTables);
    };

    const handleDeleteRow = (tableIndex, subjectIndex) => {
        const updatedTables = [...tables];
        updatedTables[tableIndex].data = updatedTables[tableIndex].data.filter((_, index) => index !== subjectIndex);
        setTables(updatedTables);
    };

    useEffect(() => {
        const errorTimeout = setTimeout(() => {
            setShowErrors(true);
        }, DELAY_BEFORE_ERROR);

        return () => clearTimeout(errorTimeout);
    }, []);
    const checkFailure = () => {
        return !areQueryValidated || !allTableQuerySet();
    };

    useEffect(() => {
        setValidationCheck(() => () => !checkFailure());
        return () => setValidationCheck(() => () => true);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box>
            <Box sx={styles.center}>
                <Typography variant='h5' sx={styles.sectionTitle}>
                    Tables
                </Typography>
            </Box>
            {tables.map((table, tableIndex) => (
                <Box key={tableIndex} sx={styles.section}>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={11} sm={6} md={4} lg={3}>
                            <Grid container spacing={3} justifyContent={'center'} direction={'column'}>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.name}>
                                    <TextField
                                        label='Table Title'
                                        variant='outlined'
                                        fullWidth
                                        value={table.title}
                                        onChange={(e) => handleTableNameChange(tableIndex, e)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            {tables.length === 1 ? (
                                <IconButton disabled={true} sx={{ margin: theme.spacing(1) }}>
                                    <DeleteIcon color='disabled' />
                                </IconButton>
                            ) : (
                                <TooltipMui title='Delete Table'>
                                    <IconButton
                                        onClick={() => handleDeleteTable(tableIndex)}
                                        sx={{ margin: theme.spacing(1) }}
                                    >
                                        <DeleteIcon color='error' />
                                    </IconButton>
                                </TooltipMui>
                            )}
                        </Grid>
                    </Grid>
                    <TableContainer sx={{ marginTop: theme.spacing(3) }}>
                        <Table sx={styles.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={styles.tableCell}>Subject</TableCell>
                                    <TableCell sx={styles.tableCell}>Matching Query</TableCell>
                                    <TableCell sx={styles.tableCell}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {table.data.map((subject, subjectIndex) => (
                                    <TableRow key={subjectIndex}>
                                        <TableCell sx={styles.tableCell}>
                                            <TextField
                                                sx={styles.textfiled}
                                                value={subject.subject}
                                                onChange={(e) =>
                                                    handleSubjectChange(tableIndex, subjectIndex, e.target.value)
                                                }
                                                variant='outlined'
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell sx={styles.tableCell}>
                                            {subject.subcategories &&
                                                subject.subcategories.map((subcategory, subIndex) => {
                                                    const subcategoryId = getAndSetSubcategoryId(
                                                        tableIndex,
                                                        subjectIndex,
                                                        subIndex,
                                                    );

                                                    return (
                                                        <Box key={subIndex}>
                                                            <Grid container>
                                                                <Grid item xs={2}>
                                                                    <TextField
                                                                        sx={styles.textfiled}
                                                                        value={subcategory.subject}
                                                                        onChange={(e) =>
                                                                            handleSubjectChange(
                                                                                tableIndex,
                                                                                subjectIndex,
                                                                                e.target.value,
                                                                                subIndex,
                                                                            )
                                                                        }
                                                                        variant='outlined'
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={10}>
                                                                    <LocalSegmentQueryBuilder
                                                                        key={subIndex}
                                                                        setNodes={(nodes) =>
                                                                            handleQueryChange(
                                                                                tableIndex,
                                                                                subjectIndex,
                                                                                subIndex,
                                                                                nodes,
                                                                            )
                                                                        }
                                                                        nodes={subcategory.query}
                                                                        noDevSyntax
                                                                        id={subcategoryId}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    );
                                                })}
                                            {!subject.subcategories && (
                                                <Box>
                                                    <LocalSegmentQueryBuilder
                                                        setNodes={(nodes) =>
                                                            handleQueryChange(
                                                                tableIndex,
                                                                subjectIndex,
                                                                undefined,
                                                                nodes,
                                                            )
                                                        }
                                                        nodes={subject.query}
                                                        noDevSyntax
                                                        id={getAndSetSubcategoryId(tableIndex, subjectIndex)}
                                                    />
                                                </Box>
                                            )}
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {subject.subcategories && subject.subcategories.length > 0 && (
                                                    <TooltipMui title='Remove Last Subcategory'>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleRemoveSubcategory(tableIndex, subjectIndex)
                                                            }
                                                        >
                                                            <RemoveIcon color='secondary' />
                                                        </IconButton>
                                                    </TooltipMui>
                                                )}
                                                <TooltipMui title='Add Subcategory'>
                                                    <IconButton
                                                        onClick={() => handleAddSubcategory(tableIndex, subjectIndex)}
                                                    >
                                                        <AddIcon color='primary' />
                                                    </IconButton>
                                                </TooltipMui>
                                            </Box>
                                        </TableCell>
                                        <TableCell sx={styles.tableCell}>
                                            {table.data.length === 1 ? (
                                                <IconButton disabled={true} sx={{ margin: theme.spacing(1) }}>
                                                    <DeleteIcon color='disabled' />
                                                </IconButton>
                                            ) : (
                                                <TooltipMui title='Delete Row'>
                                                    <IconButton
                                                        onClick={() => handleDeleteRow(tableIndex, subjectIndex)}
                                                        sx={{ margin: theme.spacing(1) }}
                                                    >
                                                        <DeleteIcon color='error' />
                                                    </IconButton>
                                                </TooltipMui>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                                        <TooltipMui title='Add Row'>
                                            <IconButton onClick={() => handleAddRow(tableIndex)}>
                                                <AddIcon color='primary' />
                                            </IconButton>
                                        </TooltipMui>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            ))}

            <Box sx={styles.buttonContainer}>
                <TooltipMui title='Add Table'>
                    <IconButton onClick={handleAddTable}>
                        <AddIcon color='primary' />
                    </IconButton>
                </TooltipMui>
            </Box>
            <Box sx={{ ...styles.buttonContainer, display: 'flex-end', flexDirection: 'column', textAlign: 'right' }}>
                {showErrors && !areQueryValidated && (
                    <Typography variant='body2' color='error'>
                        Please validate all queries before saving.
                    </Typography>
                )}
                {showErrors && !allTableQuerySet() && (
                    <Typography variant='body2' color='error'>
                        Please enter a query for each element of each table row.
                    </Typography>
                )}
            </Box>

            <Box sx={styles.buttonContainer}>
                <Button onClick={handleBack} disabled={checkFailure()}>
                    Back
                </Button>
                <Button onClick={handleNext} disabled={checkFailure()}>
                    Next
                </Button>
            </Box>
        </Box>
    );
};
