import { useTheme } from '@emotion/react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';

import { LittleColorlibConnector } from './StepConnector';
import { CustomStepIcon } from './StepIcon';

export const ProjectStepper = (props) => {
    const { stepTitles, step, setStep, editMode, validationCheck, errorToast, handleValidation, validationRetry } =
        props;

    const theme = useTheme();
    const styles = {
        stepper: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
            [theme.breakpoints.up('nd')]: {
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: theme.spacing(8),
                paddingRight: theme.spacing(8),
            },
        },
        stepLabel: {
            '& .MuiStepLabel-label': {
                marginTop: '4px',
            },
        },
    };

    return (
        <Stepper activeStep={step} alternativeLabel connector={<LittleColorlibConnector />} sx={styles.stepper}>
            {stepTitles.map((label, index) => (
                <Step key={index}>
                    <StepLabel
                        StepIconComponent={(props) => (
                            <CustomStepIcon
                                {...props}
                                editmode={editMode?.toString()}
                                onClick={() => {
                                    if (!editMode) {
                                        return;
                                    }

                                    if (!validationCheck()) {
                                        errorToast('Please ensure all checks passed before proceeding.');
                                        return;
                                    }

                                    setStep(index);
                                    validationRetry.current = 0;
                                    handleValidation();
                                }}
                            />
                        )}
                        sx={styles.stepLabel}
                    >
                        {label}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};
