import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { v1 as uuidv1 } from 'uuid';

import { getPanelUri } from '..';
import { actions } from '../../../actions';
import { denormalizeTree, initialState as qbuilderSpecs } from '../../../reducers/qbuilder';
import { getQueryState } from '../../../sagas/qbuilder';
import { api } from '../../../services';
import DataSets from '../DataSets';
import DatasetSpecs from './components/DatasetSpecs';
import QueryBuilder from './components/QueryBuilder';
import { ColorlibConnector, ColorlibStepIcon } from './components/QueryBuilderStepConnector';

function getSteps() {
    return ['Select segments', 'Select frames', 'Snapshot specifications'];
}

const nodesIds = ['selected_segments', 'selected_frames'];

const TIME_BEFORE_REDIRECT = 3000; // 3 seconds
const latestQueryVersion = qbuilderSpecs.query.version;

function QBuilderPanel(props) {
    const {
        requestBuildQuery,
        addNode,
        clearNodes,
        removeNode,
        error,
        clearSpecs,
        updateValidation,
        editSpecs,
        editNode,
        setVersion,
        nodes,
        evaluateQuery,
        matchingResult,
        resultFetching,
    } = props;

    const theme = useTheme();
    const style = {
        root: {
            width: '100%',
        },
        button: {
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(2),
        },
        instructions: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1),
            fontSize: '16px',
        },
        body: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        buttons: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: theme.spacing(2),
        },
        matching: {
            display: 'flex',
            justifyContent: 'center',
            height: '40px',
        },
        matchingText: {
            color: theme.palette.text.secondary,
            fontStyle: 'italic',
            fontSize: '1rem',
        },
        finalInstructions: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
            fontSize: '24px',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            textAlign: 'center',
            transition: 'color 0.3s ease',
        },
        finalBody: {
            fontSize: '16px',
            color: theme.palette.common.black,
            textAlign: 'center',
            transition: 'color 0.3s ease',
        },
        finalButtons: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: theme.spacing(4),
            transition: 'margin-top 0.3s ease',
        },
        finalButton: {
            marginRight: theme.spacing(2),
            marginTop: theme.spacing(2),
            transition: 'transform 0.3s ease',
            '&:hover': {
                transform: 'scale(1.05)',
            },
        },
    };

    const isOperator = (node) => node.type !== 'FILTER';
    const navigate = useNavigate();
    const classes = style;
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const steps = getSteps();
    const queryState = useSelector(getQueryState);
    const [searchParams, setSearchParams] = useSearchParams();
    const [timer, setTimer] = useState(null);
    const [matchingElements, setMatchingElements] = useState(null);
    const redirectTimeout = useRef(null);
    const redirectInterval = useRef(null);

    const createRecSubNode = (parentId, elt, negate = false) => {
        while (isOperator(elt) && elt['name'] === 'NOT') {
            negate = !negate;
            if (!elt['body']) {
                console.warn('Empty NOT operator');
                return;
            }
            elt = elt['body'];
        }

        editNode({
            id: parentId,
            type: elt['type'],
            name: elt['name'],
            body: elt['type'] === 'FILTER' ? elt['body'] : [],
            negate: negate,
        });

        if (elt['type'] !== 'OPERATOR') {
            return;
        }

        for (const child of elt['body']) {
            const childId = uuidv1();
            addNode({ id: childId, parentId: parentId });
            createRecSubNode(childId, child);
        }
    };

    useEffect(() => {
        try {
            const existingQuery = searchParams.get('query');
            if (existingQuery) {
                const decodedQuery = atob(existingQuery);
                const decodedQueryJson = JSON.parse(decodedQuery);

                // Parse specs
                const specs = decodedQueryJson['specs'];
                editSpecs({
                    name: specs['name'],
                    dataToInclude: specs['include'],
                    frameEncoding: specs['encoding_frames'],
                    maskEncoding: specs['encoding_masks'],
                });

                const query = decodedQueryJson['query'];
                // Parse Segment Filters
                const selectedSegments = query['selected_segments'];

                if (selectedSegments) {
                    const segmentId = nodesIds[0];
                    addNode({ id: segmentId });
                    createRecSubNode(segmentId, selectedSegments);
                }

                // Parse Frame Filters
                const selectedFrames = query['selected_frames'];
                if (selectedFrames) {
                    const frameId = nodesIds[1];
                    addNode({ id: frameId });
                    createRecSubNode(frameId, selectedFrames);
                }

                const version = query['version'];
                if (version) {
                    setVersion(version);
                }
            } else {
                setVersion(latestQueryVersion);
            }
        } catch (error) {
            console.warn('Failed to parse query from URL. Resetting query builder.');
            console.warn(error);
            clearNodes();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!matchingResult || matchingResult?.nb_data === undefined) return;
        setMatchingElements(matchingResult.nb_data);
        console.log('Number of matching annotations:', matchingResult.nb_data);
        console.log('Detailed annotations:', matchingResult.data);
    }, [matchingResult]);

    useEffect(() => {
        setMatchingElements(null);
        if (error || activeStep !== 0) return;
        const id = nodesIds[activeStep];
        const query = denormalizeTree(id, nodes);
        console.log('Executing query:', JSON.stringify(query));
        evaluateQuery(query);
    }, [error, activeStep, nodes, evaluateQuery]);

    useEffect(() => {
        const modifiedQueryState = {
            specs: {
                name: queryState.specs?.name,
                include: queryState.specs?.dataToInclude,
                encoding_frames: queryState.specs?.frameEncoding,
                encoding_masks: queryState.specs?.maskEncoding,
            },
            query: {
                selected_segments: queryState.querySelectedSegments,
                selected_frames: queryState.querySelectedFrames,
                version: queryState.version,
            },
        };

        const queryStateString = queryState ? JSON.stringify(modifiedQueryState) : '';
        const encodedData = btoa(queryStateString);
        setSearchParams({ query: encodedData }, { replace: true });
    }, [setSearchParams, queryState]);

    useEffect(() => {
        nodesIds.forEach((id) => addNode({ id }));
        return () => {
            clearNodes();
        };
    }, [addNode, clearNodes]);

    const handleReset = () => {
        setActiveStep(0);
        clearSpecs();
        skipped.clear();
        nodesIds.forEach((id) => addNode({ id }));
        if (redirectTimeout.current) clearTimeout(redirectTimeout.current);
        if (redirectInterval.current) clearInterval(redirectInterval.current);
    };

    useEffect(() => {
        if (activeStep === steps.length) {
            setTimer(TIME_BEFORE_REDIRECT / 1000);

            redirectInterval.current = setInterval(() => {
                setTimer((timer) => timer - 1);
            }, 1000);

            redirectTimeout.current = setTimeout(() => {
                navigate(getPanelUri(<DataSets />));
                return () => clearInterval(redirectInterval.current);
            }, TIME_BEFORE_REDIRECT);
        }
    }, [activeStep, navigate, steps.length]);

    useEffect(() => {
        return () => {
            if (redirectInterval.current) {
                clearInterval(redirectInterval.current);
            }
        };
    }, []);

    useEffect(() => {
        return () => {
            if (redirectTimeout.current) {
                clearTimeout(redirectTimeout.current);
            }
        };
    }, []);

    useEffect(() => {
        if (timer !== null && timer <= 0) {
            navigate(getPanelUri(<DataSets />));
            try {
                clearInterval(redirectInterval.current);
            } catch (error) {
                console.log(error);
            }

            try {
                clearTimeout(redirectTimeout.current);
            } catch (error) {
                console.log(error);
            }
        }
    }, [timer, navigate]);

    function getStepContent(step) {
        const stepsContent = [
            <QueryBuilder key={0} id={nodesIds[0]} filterType='segment' />,
            <QueryBuilder key={1} id={nodesIds[1]} filterType='frame' />,
            <DatasetSpecs submit={handleFinish} back={handleBack} />,
        ];
        return stepsContent[step];
    }

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isFirstStep = (step) => {
        return step === 0;
    };

    const isLastStep = (step) => {
        return step === steps.length - 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleFinish = () => {
        skipped.forEach((v) => {
            removeNode({ id: nodesIds[v] });
        });

        requestBuildQuery();

        handleNext();
    };
    const handleNext = () => {
        let newSkipped = skipped;

        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        updateValidation(nodesIds[activeStep + 1]);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        updateValidation(nodesIds[activeStep - 1]);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        skipped.delete(activeStep);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) throw new Error("You can't skip a step that isn't optional.");

        updateValidation(nodesIds[activeStep + 1]);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = <Typography variant='caption'>Optional</Typography>;
                    }

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel
                                {...labelProps}
                                StepIconComponent={(iconProps) => (
                                    <ColorlibStepIcon skipped={isStepSkipped(index)} {...iconProps} />
                                )}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <Grid sx={classes.body}>
                {activeStep === steps.length ? (
                    <Grid item>
                        <Grid container sx={classes.root} alignItems='center' justify='center'>
                            <Grid item xs={12}>
                                <Typography variant='h4' sx={classes.finalInstructions}>
                                    Snapshot task submitted!
                                </Typography>
                                <Typography variant='body1' sx={classes.finalBody}>
                                    You will be redirected to the Snapshots page in {timer} seconds...
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={classes.finalButtons}>
                                    <Button
                                        onClick={handleReset}
                                        color='primary'
                                        variant='contained'
                                        sx={classes.finalButton}
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container direction='column' spacing={3} sx={{ maxWidth: '80%' }}>
                        <Grid item>{getStepContent(activeStep)}</Grid>
                        {!isLastStep(activeStep) && (
                            <>
                                <Grid item sx={classes.matching}>
                                    {(matchingElements !== null || resultFetching) && (
                                        <>
                                            {matchingElements !== null ? (
                                                <Tooltip title={'Open the console to see more details'} arrow>
                                                    <Box display='flex' alignItems='center' flexDirection='column'>
                                                        {matchingElements === 0 ? (
                                                            <>
                                                                <WarningAmberIcon
                                                                    sx={{
                                                                        marginBottom: 1,
                                                                        color: theme.palette.text.warning,
                                                                    }}
                                                                />
                                                                <Typography
                                                                    variant='body1'
                                                                    sx={{
                                                                        ...classes.matchingText,
                                                                        color: theme.palette.text.warning,
                                                                        fontWeight: 'bold',
                                                                        textAlign: 'center',
                                                                    }}
                                                                >
                                                                    No matching annotations
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <PlagiarismIcon
                                                                    sx={{
                                                                        marginBottom: 1,
                                                                        color: theme.palette.text.success,
                                                                    }}
                                                                />
                                                                <Typography
                                                                    variant='body1'
                                                                    sx={{
                                                                        ...classes.matchingText,
                                                                        color: theme.palette.text.success,
                                                                    }}
                                                                >
                                                                    {`Found ${matchingElements} matching annotations`}
                                                                </Typography>
                                                            </>
                                                        )}
                                                    </Box>
                                                </Tooltip>
                                            ) : (
                                                <Box display='flex' alignItems='center' flexDirection='column'>
                                                    <CircularProgress size={24} sx={{ marginBottom: 1 }} />
                                                    <Typography
                                                        variant='body1'
                                                        sx={{ ...classes.matchingText, textAlign: 'center' }}
                                                    >
                                                        Fetching matching annotations...
                                                    </Typography>
                                                </Box>
                                            )}
                                        </>
                                    )}
                                </Grid>

                                <Grid item sx={classes.buttons}>
                                    {!isFirstStep(activeStep) && (
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={classes.button}
                                            data-testid='back-button'
                                        >
                                            Back
                                        </Button>
                                    )}
                                    {isStepOptional(activeStep) && (
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            onClick={handleSkip}
                                            sx={classes.button}
                                            data-testid='skip-button'
                                        >
                                            Skip
                                        </Button>
                                    )}

                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={handleNext}
                                        sx={classes.button}
                                        disabled={error}
                                        data-testid='next-button'
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        error: state.qbuilder.error,
        nodes: state.qbuilder.nodes,
        matchingResult: state.data[api.endpoints.qbuilderEvaluate]?.data,
        resultFetching: state.data[api.endpoints.qbuilderEvaluate]?.isPosting,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeNode: (id) => dispatch(actions.qbuilder.node.remove(id)),
        clearNodes: () => dispatch(actions.qbuilder.node.clear()),
        clearSpecs: () => dispatch(actions.qbuilder.specs.clear()),
        requestBuildQuery: () => dispatch(actions.api.qbuilder.build.request()),
        updateValidation: (id) => dispatch(actions.qbuilder.validation.update(id)),
        editSpecs: (...args) => dispatch(actions.qbuilder.specs.edit(...args)),
        addNode: ({ id, parentId }) => dispatch(actions.qbuilder.node.add({ id, parentId })),
        editNode: ({ id, type, name, body, negate }) =>
            dispatch(actions.qbuilder.node.edit({ id, type, name, body, negate })),
        setVersion: (version) => dispatch(actions.qbuilder.query.version(version)),
        evaluateQuery: (query) =>
            dispatch(
                actions.api.data.post.request({
                    endpoint: api.endpoints.qbuilderEvaluate,
                    data: { query: query },
                }),
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QBuilderPanel);
