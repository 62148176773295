import { useTheme } from '@emotion/react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

import LocalSegmentQueryBuilder from '../../../../../QBuilder/components/LocalSegmentQBuilder';
import { DELAY_BEFORE_ERROR } from '../../constant';

export const ProjectDetailsForm = ({
    projectName,
    setProjectName,
    projectQuery,
    setProjectQuery,
    developmentQuery,
    setDevelopmentQuery,
    projectQueryId,
    developmentQueryId,
    areQueryValidated,
    handleNext,
    setValidationCheck,
}) => {
    const [showErrors, setShowErrors] = useState(false);
    const theme = useTheme();
    const styles = {
        buttonContainer: {
            margin: theme.spacing(2),
            display: 'flex',
            justifyContent: 'flex-end',
        },
        center: {
            width: '100%',
            textAlign: 'center',
        },
        queryItem: {
            marginTop: theme.spacing(3),
        },
        section: {
            backgroundColor: theme.palette.common.sectionGray,
            margin: `4px 0 20px 0`,
            borderRadius: '5px',
            padding: theme.spacing(3),
        },
        sectionTitle: {
            marginBottom: theme.spacing(2),
        },
        separator: {
            border: `1px solid ${theme.palette.divider}`,
            width: '100%',
        },
        name: {
            marginBottom: theme.spacing(3),
        },
    };

    useEffect(() => {
        const errorTimeout = setTimeout(() => {
            setShowErrors(true);
        }, DELAY_BEFORE_ERROR);

        return () => clearTimeout(errorTimeout);
    }, []);

    const checkFailure = useCallback(() => {
        return (
            !areQueryValidated ||
            !projectName ||
            !projectQuery.length ||
            !projectQuery[0]?.name ||
            !developmentQuery.length ||
            !developmentQuery[0]?.name
        );
    }, [areQueryValidated, projectName, projectQuery, developmentQuery]);

    useEffect(() => {
        setValidationCheck(() => () => !checkFailure());
        return () => setValidationCheck(() => () => true);
    }, [checkFailure]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Box sx={styles.center}>
                <Typography variant='h5' sx={styles.sectionTitle}>
                    Specs
                </Typography>
            </Box>
            <Box sx={styles.section}>
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <Grid container spacing={3} justifyContent={'center'} direction={'column'}>
                            <Grid item xs={12} sm={12} md={12} lg={12} sx={styles.name}>
                                <TextField
                                    label='Project Name'
                                    variant='outlined'
                                    fullWidth
                                    value={projectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'center'} direction={'column'}>
                    <hr style={styles.separator} />
                    <Grid item sx={styles.queryItem}>
                        <Box sx={styles.center}>
                            <Typography variant='h6'>Project Query</Typography>
                        </Box>
                        <LocalSegmentQueryBuilder
                            setNodes={setProjectQuery}
                            nodes={projectQuery}
                            id={projectQueryId}
                            noDevSyntax
                        />
                    </Grid>
                    <hr style={styles.separator} />
                    <Grid item sx={styles.queryItem}>
                        <Box sx={styles.center}>
                            <Typography variant='h6'>Development Query</Typography>
                        </Box>
                        <LocalSegmentQueryBuilder
                            setNodes={setDevelopmentQuery}
                            nodes={developmentQuery}
                            id={developmentQueryId}
                            noDevSyntax
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ ...styles.buttonContainer, display: 'flex-end', flexDirection: 'column', textAlign: 'right' }}>
                {showErrors && !areQueryValidated && (
                    <Typography variant='body2' color='error'>
                        Please validate all queries before saving.
                    </Typography>
                )}
                {showErrors && !projectName && (
                    <Typography variant='body2' color='error'>
                        Please enter a project name.
                    </Typography>
                )}
                {showErrors && (!projectQuery.length || !projectQuery[0]?.name) && (
                    <Typography variant='body2' color='error'>
                        Please enter a project query.
                    </Typography>
                )}
                {showErrors && (!developmentQuery.length || !developmentQuery[0]?.name) && (
                    <Typography variant='body2' color='error'>
                        Please enter a development query.
                    </Typography>
                )}
            </Box>

            <Box sx={styles.buttonContainer}>
                <Button disabled={true}>Back</Button>
                <Button onClick={handleNext} disabled={checkFailure()}>
                    Next
                </Button>
            </Box>
        </>
    );
};
